var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
var ViewImage = /** @class */ (function (_super) {
    __extends(ViewImage, _super);
    function ViewImage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.urlPath = '';
        return _this;
    }
    ViewImage.prototype.onUrlChanged = function (val) {
        this.urlPath = val;
    };
    __decorate([
        Prop()
    ], ViewImage.prototype, "url", void 0);
    __decorate([
        Watch('url', { immediate: true })
    ], ViewImage.prototype, "onUrlChanged", null);
    ViewImage = __decorate([
        Component({
            components: {}
        })
    ], ViewImage);
    return ViewImage;
}(Vue));
export default ViewImage;
